@import "~@bphxd/ds-core-react/lib/scss/bp-core";

@mixin roboto($font-size, $line-height, $font-weight: 300) {
  font-family: Roboto;
  font-weight: $font-weight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  font-size: $font-size;
  line-height: $line-height;
}
.global-product-tour-intro,
.khub-edit-tour-intro,
.khub-tour-intro,
.search-product-tour-intro {
  .introjs-tooltiptext {
    padding: 40px 60px 56px 60px;
  }
  .introjs-tooltip-header {
    background-image: url(/images/gif/Homepage_loop.gif);
    background-size: 541px 296px;
    height: 296px;
    border-top-right-radius: 4px;
    border-top-left-radius: 5px;
    margin: 0;
    padding: 0;
  }
  .header {
    @include roboto(26px, 1.5);
    color: rgba(17, 17, 17, 0.92);
  }
  p.body {
    @include roboto(18px, 1.61);
    color: rgba(17, 17, 17, 0.84);
  }
}

.khub-tour-intro {
  .introjs-tooltip-header {
    background-image: url("/images/gif/KHub view_loop.gif");
  }
}

.khub-edit-tour-intro {
  .introjs-tooltip-header {
    background-image: url("/images/gif/KHub edit_loop.gif");
  }
}

.search-product-tour-intro {
  .introjs-tooltip-header {
    background-image: url("/images/gif/Search_loop.gif");
  }
}

.global-product-tour-intro,
.global-product-tour-outro,
.generic-product-tour-outro,
.khub-tour-intro,
.khub-edit-tour-intro,
.search-product-tour-intro {
  width: 540px;

  .introjs-skipbutton {
    width: 36px;
    font-size: 32px;
    font-weight: 300;
  }

  .introjs-tooltipbuttons {
    @extend .px-4, .pb-4;
    padding-top: 6px;
  }

  .introjs-button {
    @include roboto(0.875rem, 2rem, 400);
  }
}

.global-product-tour-outro,
.generic-product-tour-outro {
  .introjs-tooltip-header {
    margin: 0px;
    @extend .justify-content-between, .py-0, .my-0, .px-4;
  }

  .introjs-tooltiptext {
    padding: 0px 45px 40px 45px;
  }

  .header {
    @include roboto(23px, 1.52);
    color: rgba(17, 17, 17, 0.84);
  }
  .body {
    @include roboto(16px, 1.75);
    letter-spacing: 0.15px;
    color: rgba(17, 17, 17, 0.84);
  }
}

.introjs-hint-pulse {
  background-color: rgb(33 141 204 / 33%);
  z-index: 0;
}

.introjs-hint:hover > .introjs-hint-pulse {
  background-color: rgba(33 141 204 / 57%);
}

.introjs-hint {
  @extend .ms-3;
}

@keyframes introjspulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(33 141 204 / 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 transparent;
  }
}

.introjs-helperLayer {
  box-shadow:
    rgba(33, 33, 33, 0.8) 0px 0px 0px 0px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

.introjs-tooltipReferenceLayer {
  font-family: unset;
}

.introjs-tooltip {
  @extend .lh-1-5, .fs-6, .text-op-dark-gray-100;
  font-family: Roboto !important;
  font-stretch: normal;
  font-style: normal;
  max-width: unset;
}

.introjs-tooltipbuttons {
  border-top: unset;
}

.introjs-tooltip-header {
  display: flex;
  padding-top: 16px;
  margin-bottom: 12px;
}

.introjs-skipbutton {
  @extend .lh-1-5;

  position: unset;
  height: unset;
  font-weight: inherit;
  line-height: unset;
  font-size: inherit;
  text-align: right;
}

.introjs-tooltip-title {
  @extend .text-op-dark-gray-700, .fs-base, .lh-1-5;
  font-weight: inherit;
}

.introjs-tooltiptext {
  font-family: inherit;
  color: inherit;
  padding-top: 0;
  margin-top: 0;
}

%bp-core-btn {
  @extend .btn, .rounded-pill, .btn-xs;
  text-shadow: none;
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.introjs-nextbutton,
.introjs-donebutton {
  @extend %bp-core-btn, .btn-standard-primary;
}

.introjs-button {
  @extend %bp-core-btn, .btn-standard-primary;
  &:focus {
    box-shadow: unset;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
  }
}

.customHintClass {
  p {
    padding-top: 20px;
  }
}

.introjs-prevbutton {
  @extend %bp-core-btn, .btn-standard-tertiary;
}

.introjs-prevbutton.introjs-disabled {
  @extend .disabled;
}

.introjs-progress {
  @extend .progress;
  height: 2px;
}

.introjs-progressbar {
  @extend .progress-bar;
}

.introjs-dontShowAgain {
  label {
    @extend .lh-1-75, .text-op-dark-gray-100;
    cursor: pointer;
  }
}
