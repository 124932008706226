.hover-shadow {
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    opacity: 1;
  }
}

.hover-background {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.no-hover-color {
  &:hover {
    color: initial;
  }
}

.bp-carousel-controls {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  margin-top: -12px;
}

.bp-carousel-arrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
  &.bp-carousel-arrow__right {
    transform: rotateY(180deg);
  }
  &.bp-carousel-arrow__disabled {
    cursor: not-allowed;
    pointer-events: none;
    svg {
      opacity: 0.25;
    }
  }
}

.bp-carousel-main {
  display: flex;
  padding: 0 30px;
}
.bp-carousel-search-main {
  display: grid;
}
.bp-carousel {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bp-carousel__progress-bar {
  background-color: #fff;
  border-radius: 2px;
  height: 3px;
  width: 230px;
  margin-left: 30px;
  position: relative;
}

.bp-carousel__progress-indicator {
  background-color: #767676;
  border-radius: 2px;
  height: 3px;
  width: 120px;
  position: absolute;
  transition: left 500ms;
}

.bp-carousel__edge {
  width: 1px;
  margin: 24px 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 5px 0 20px 0 rgb(0 0 0);
  z-index: 0;
}

.bp-carousel__edge ~ .bp-carousel__edge {
  box-shadow: none;
}

.bp-carousel-items {
  display: flex;
  width: 100%;
  margin: 24px 0;
  gap: 15px;
}

.bp-carousel__right-spacing {
  padding-right: 15px;
}

.bp-carousel__right-spacing-limited {
  padding-left: 119px;
  padding-right: 104px;
  display: flex;
  place-items: center;
  font-size: 20px;
}

.bp-carousel__right-spacing-limited-text {
  width: 190px;
  text-align: left;
}

.bp-carousel__right-cta {
  padding: 0;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  margin-top: 10px;
  text-align: left;
  color: #002bbc;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.bp-search-bar {
  height: 44px;
  flex-grow: 1;
  display: flex;
  padding: 5px 8px 3px 17px;
  margin-top: 32px;
  margin-bottom: 32px;
  border: none;
  border-radius: 68px;
  background-color: #f2f2f2;
  position: relative;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    background-color: transparent;
    padding: 0;
    border: none;
    height: 32px;
    width: calc(100% - 84px);
    margin: 0 0 0 8px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: rgba(17, 17, 17, 0.84);
    min-width: 70px;
  }

  input::placeholder {
    color: rgba(17, 17, 17, 0.7);
  }

  input:focus {
    outline: none;
  }

  svg {
    opacity: 0.5;
  }

  .bp-search-suggestions {
    top: 75px;
    left: 0;
    position: absolute;
    z-index: 5;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    a:hover {
      text-decoration: none;
    }

    &.fadeIn {
      visibility: visible;
      opacity: 1;
      transition:
        visibility 0s linear 0s,
        opacity 300ms;
    }

    &.fadeOut {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s linear 300ms,
        opacity 300ms;
    }
  }
}

.component-category--items {
  margin: 0 30px;
  margin-top: 24px;
}

.tab-plain {
  font-family: Arial;
  font-weight: normal;
  font-size: 13px;
  width: auto;
  display: inline-flex;
  margin: 0 10px 24px 10px;
  padding: 4px 10px;
  line-height: 1.54;
  letter-spacing: 0.8px;
  color: rgba(17, 17, 17, 0.64);
  align-items: center;
  cursor: pointer;
  &.selected {
    border-bottom: solid 1px #a1a1a1;
    color: rgba(17, 17, 17, 0.84);
  }

  .rectangle-tab--text {
    padding: 0px 15px 0px 15px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #111;
  }
}

.component-card-truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rectangle {
  width: fit-content;
  display: inline-flex;
  min-height: 27px;
  margin-top: 12px;
  padding: 5px 10px;
  background-color: #f2f2f2;
  align-items: center;

  .rectangle-icon {
    margin-right: 10px;
    display: flex;

    img {
      height: 16px;
    }
  }

  .rectangle-text {
    text-transform: uppercase;
    min-height: 11px;
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.1;
    letter-spacing: 1px;
    text-align: right;
    color: #111;
  }
}

.user-component__image {
  height: 64px;
  width: 64px;
}

.user-component--items {
  margin: 12px 0 0 0;
}

.user-component--wrapper {
  @include media-breakpoint-up(md) {
    &:nth-child(odd) {
      padding: 0 30px 0 0;
    }
    &:nth-child(even) {
      padding: 0 0 0 30px;
    }
  }
}
.user-component {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 0 !important;
  cursor: pointer;
  align-items: center;

  .user-component__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;

    .user-component__title {
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: rgba(17, 17, 17, 0.8);
    }

    .user-component__user {
      display: flex;
      margin-top: 12px;
    }

    .user-component__name {
      margin-left: 8px;
      opacity: 0.6;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.3px;
      color: rgba(17, 17, 17, 0.7);
    }
  }

  .user-component__arrow {
    align-self: center;
  }
}

.bp-banner__header__image {
  display: flex;
  justify-content: flex-start;
  width: 250px;
}

.bp-banner__text {
  justify-content: flex-start;
  text-align: left;
  padding-left: 30px;
}

.bp-banner__header {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  text-align: right;
  color: #fff;
  background-size: cover;
}

.bp-code-hub-banner {
  margin: 1rem 3.5rem 0 0;
}
.bp-header__dismiss {
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  float: right;
  justify-content: flex-end;
  color: rgba(17, 17, 17, 0.64);
  margin-right: 50px;
  overflow-x: hidden;
  cursor: pointer;

  &:hover {
    color: rgba(17, 17, 17);
  }
}
.bp-header-title {
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  max-width: 70%;
  margin: 0 94px 16px 0;
  color: black;
}

.bp-hero-content {
  padding-left: 40px;

  &.executablefunction-hero {
    .card-description__wrapper {
      background-color: #e5f5fc;
    }
  }

  &.codeblock-hero {
    .card-description__wrapper {
      background-color: #fffbea;
    }
  }

  .card-title {
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #111;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
  }

  // .card-description__wrapper {
  //   padding: 24px 30px;
  //   margin-top: 24px;
  //   // background-color: #fffbea;
  // }

  // .bp-hero__card-description {
  //   margin-top: 0;
  //   max-width: 50%;
  //   font-size: 16px;
  //   font-weight: 300;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.75;
  //   letter-spacing: 0.2px;
  //   color: rgba(17, 17, 17, 0.7);
  // }
}

.bp-hero-icon {
  height: 48px;
  width: 48px;
}

.bp-hero-illustration {
  position: absolute;
  right: 0;
}

.bp-hero-title {
  min-height: 90px;
  display: flex;
}

.feature__items {
  margin: 24px 30px 0 30px;
}

.feature {
  .feature__card {
    min-height: 130px;
    display: flex;
    padding: 12px 15px 12px 15px;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: #fff;
    border-radius: 5px;

    img {
      height: 24px;
    }

    // &:hover {
    //   box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    // }
  }
  .feature__options {
    position: absolute;
    right: 5px;
    top: 15px;

    .nav-link {
      cursor: pointer;
      padding: 0;
      svg {
        color: rgba(17, 17, 17, 0.64);
      }
    }

    &:hover {
      .nav-link svg {
        color: rgba(17, 17, 17, 1);
      }
    }
  }

  .dropdown-menu {
    border-radius: 5px;
  }

  .feature__dropdown-item {
    svg {
      margin-right: 10px;
    }
  }

  .feature__title {
    margin: 0 15px 0px 0px;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.2px;
    color: #111;
  }

  .feature__description {
    margin: 12px 15px 0 0;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: 0.25px;
    color: rgba(17, 17, 17, 0.7);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-dialog {
  margin-top: 100px;
  max-height: calc(100vh - 130px);
}

@include media-breakpoint-down(md) {
  .modal-sm,
  .modal-md,
  .modal-lg,
  .modal-xl {
    min-width: 768px;
  }
}

.rotate-360-loop {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.star--selected,
.star--not-selected {
  cursor: pointer;
}

.star--selected {
  svg #outline {
    stroke: black;
    fill: black;
  }
  &:hover {
    svg #outline {
      stroke: black;
      fill: transparent;
    }
  }
}

.star--not-selected {
  svg #outline {
    stroke: black;
    fill: transparent;
  }
  &:hover {
    svg #outline {
      stroke: black;
      fill: black;
    }
  }
}

.dark .md-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li, pre, tr, th, td, blockquote {
    color: #fff;
  }
}

.md-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 56px;
  }

  h1 {
    font-size: 32px;
    font-weight: 300;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 18px;
    font-weight: 300;
  }

  h4,
  h5,
  h6 {
    font-size: 16px;
  }

  p {
    color: #111;
    opacity: 1;
  }



  a {
    word-break: break-word;
  }

  table {
    border-spacing: 10px;
    border-collapse: collapse;
    table-layout: auto;
    margin: 0 3px 16px 3px;
    overflow: scroll;
    // width: calc(100% - 1rem);
    border: 1px solid lightgrey;

    img {
      max-width: initial;
    }
  }
  table tr:nth-child(2n + 2) {
    background-color: #f2f2f2;
  }
  th {
    border-spacing: 10px;
    border-collapse: collapse;
    text-align: center;
    padding: 5px;
    color: #ffffff !important;
    background: #009900;
    text-align: center;
    font-weight: 100 !important;

    a {
      color: #ffffff !important;
    }
  }
  pre {
    background-color: #eeeeee;
    border: 1px solid #eee;
    border-radius: 3px;
  }
  tr {
    font-weight: 100;
  }
  td {
    vertical-align: middle;
    padding: 10px;
    color: #333333;

    a {
      color: #009900;
      line-height: 1.5;
    }
  }
  td:last-child {
    padding: 2px;
    width: auto;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
    border: none;
    border-left: 10px solid #d6dbdf;
    color: #333;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    position: relative;

    p {
      margin: 1rem 0;
    }
  }
}

.file_preview {
  border: 1px solid #d2d2d2;
  overflow-x: scroll;
  position: relative;
  max-height: calc(45vh);

  .file_preview__wrapper {
    display: grid;
    width: 100%;
  }

  pre {
    margin: 0;
  }
}

.popover {
  width: 240px;
  margin: 0 10px 0 0;
  padding: 16px 20px 18px 20px;
  border-radius: 5px;
  background-color: #232323;
}

.popover-header {
  width: 200px;
  margin: 0 0 12px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #fff;
}

.popover-body {
  width: 200px;
  margin: 12px 0 0;
  opacity: 0.7;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
}
